@import '../variables';

.text-editor {
  position: relative;
  box-sizing: border-box;
  border: 1px solid black;
  margin: 0;
  height: 100vh;
  text-align: left;
  outline: none;

  .selected-lines {
    background: $primary-colour-dark;
    width: 5px !important;
    margin-left: 3px;
  }
}
