@import '../variables';

.login-form {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;

  > * {
    margin: 1em;
  }

  &__form {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $colour-medium-gray;
  }

  &__button {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}

.login-form-email {
  @extend .login-form;
}

.login-form-oauth {
  @extend .login-form;
}
