.grouped-inputs {
  display: inline-block;
  white-space: nowrap;
  > * {
    vertical-align: middle;
  }
  > :first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  > :last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  > :not(:first-child):not(:last-child) {
    border-radius: 0;
    border-right: none;
  }
}
