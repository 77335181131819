.note-editor-page {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100vh;
  overflow-y: hidden;
  .editor-container {
    .lines-container {
      resize: horizontal;
    }
  }
  .preview-container {
    text-align: left;
    padding: 20px;
    background-color: white;
    overflow: scroll;
  }
}

.rendered-note {
  mjx-container {
    overflow-x: auto;
    scrollbar-width: none;
  }

  img {
    max-width: 100%;
    border: 1px solid #f0f0f0;
  }

  h1 {
    margin: 0;
  }
}
