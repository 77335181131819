@import 'variables';

.pdf-viewer {
  text-align: center;
  background-color: black;
  overflow: auto;
  width: 100%;
  height: 100vh;

  display: grid;
  row-gap: 5px;
  column-gap: 5px;

  &__page-container {
    justify-self: center;
    position: relative;
    background-color: white;
  }

  &__text-layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1.0;
    > * {
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
      &::selection {
        color: blue;
        background: blue;
      }
    }
    canvas {
      display: none;
    }
  }

  &__text-layer-custom {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1.0;
    > span {
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
      text-align: justify;
      &::selection {
        //color: white;
        color: transparent;
        background: blue;
      }
    }
    canvas {
      display: none;
    }
  }

  &__annotation-layer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
  }

  &__annotation {
    position: absolute;
    cursor: pointer;
  }
}
