@import 'variables';

body {
}

.annotation-page {
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: auto min-content;
  height: 100vh;

  .sidebar-container {
    grid-column: 2;
    grid-row: 1 / span 2;
    height: 100vh;
    position: relative;
    box-shadow: -3px 5px 5px rgba(0,0,0,0.2);
  }
  > .controls {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 0;
    color: white;
    background-color: $colour-medium-dark-gray;
    overflow: hidden;
    button {
      padding: 5px;
      i {
        text-align: middle;
      }
    }
    .active {
      background-color: $primary-colour-light;
      color: $primary-colour-dark;
    }
    .page-selector {
      display: inline-block;
      input {
        width: 5em;
      }
    }
  }

  .pdf-viewer {
    height: 100%;
    background-color: rgb(240,240,240);
  }

  .custom-annotation-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    canvas {
      mix-blend-mode: multiply;
    }
  }

  .text-layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1.0;
    > * {
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
      &::selection {
        color: blue;
        background: blue;
      }
    }
    &.hidden {
      display: none;
    }
  }

  .annotation {
    box-sizing: border-box;
    &:focus, &.active {
      outline: 1px solid black;
    }
    &.point {
      background-color: rgba(red,0.5);
      width: 10px;
      height: 10px;
      position: absolute;
      transform: translate(-5px,-5px);
    }
    &.rect {
      background-color: rgba(black,0.2);
      position: absolute;
      left: 0;
      top: 0;
      .control {
        position: absolute;
        border-radius: 5px;
        background-color: white;
        border: 1px solid grey;
        width: 10px;
        height: 10px;
        transform: translate(-5px,-5px);
        &.nw {
          cursor: nw-resize;
          top:0;
          left:0;
        }
        &.n {
          cursor: n-resize;
          top:0;
          left:50%;
        }
        &.ne {
          cursor: ne-resize;
          top:0;
          left:100%;
        }
        &.sw {
          cursor: sw-resize;
          top:100%;
          left:0;
        }
        &.s {
          cursor: s-resize;
          top:100%;
          left:50%;
        }
        &.se {
          cursor: se-resize;
          top:100%;
          left:100%;
        }
        &.w {
          cursor: w-resize;
          top:50%;
          left:0;
        }
        &.e {
          cursor: e-resize;
          top:50%;
          left:100%;
        }
      }
    }
    &.selected {
      outline: 1px solid black;
      cursor: move;
    }
    .actions-container {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%,0);
      white-space: nowrap;
      > * {
        margin: 5px 2px;
      }
    }
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 1;
    height: 1;
    overflow: visible;
    path.annotation {
      stroke: yellow;
      //stroke-width: 1em;
      mix-blend-mode: multiply;
      stroke-opacity: 0.3;
      fill: none;
      &.active {
        stroke: green;
        outline: none;
      }
      &.selected {
        stroke: blue;
        outline: none;
      }
      &:focus {
        outline: none;
      }
    }
  }

  .card {
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    border: 1px solid rgb(200,200,200);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    text-align: left;
    transition: 0.2s ease;
    font-size: 10pt;

    &.active {
      box-shadow: 0px 0px 0px 2px rgba(black,0.8);

      .controls {
        display: block;
        text-align: left;
        border-top: 1px solid rgb(200,200,200);
        padding-top: 10px;
        > .grouped-inputs, > .input {
          margin-right: 3px;
        }
        span {
          margin-right: 5px;
          cursor: pointer;
        }
        .advanced {
          margin: 10px;
        }
        .advanced-toggle {
          cursor: pointer;
          font-size: 12px;
          color: blue;
          margin: 10px;
        }
      }
    }

    .controls {
      display: none;
    }

    .text-editor {
      width: 100%;
      resize: vertical;
      max-height: 90vh;
    }

    img {
      max-width: 100%;
    }
  }

  .sidebar {
    width: 50vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: white;
    padding: 1em 2.5em;
    padding-right: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: left;
    transition: 0.2s ease;
    background-color: rgb(240,240,240);
    &.resizing {
      transition: none;
      cursor: e-resize;
    }
    .tab-container {
      display: flex;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid grey;
      max-width: 100%;
      overflow: hidden;
      .active {
        background-color: $colour-medium-gray;
      }
    }
    input, textarea {
      width: 100%;
    }
    &.hidden {
      width: 0;
      margin-left: 32px;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
    }
    > .controls {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      cursor: pointer;
      width: 32px;
      > * {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover {
        background-color: grey;
      }
    }
  }

  .doc-notes-container {
    .text-editor {
      max-height: calc(100vh - 250px);
    }
  }

  .outline {
    padding: 10px;
    background: white;
    border-radius: 3px;

    &__list {
    }

    &__link {
      &:hover {
        cursor: pointer;
        color: $primary-colour-dark;
      }
    }
  }

  .doc-info-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 2em 0;
    gap: 1.5em;
    > label {
      width: 100%;
    }
    textarea {
      height: 100px;
    }
  }
}
