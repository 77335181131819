.landing-page {
  background-size: cover;
  min-height: 100vh;
  margin: 0;
  padding: 10px;
  text-align: center;

  .row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: row wrap;
    height: 100%;
    > div {
      display: inline-block;
      margin: 10px;
    }
    .description {
      padding: 50px 30px;
      max-width: 500px;
      flex-basis: 500px;
      text-align: left;
    }
    .signup-form-container {
    }
  }

  a {
    margin: 0 10px;
  }
}
