@import 'variables';

.input {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;

  font-family: 'Montserrat', sans-serif;

  i.material-icons {
    vertical-align: middle;
    cursor: pointer;
  }
  input[type='checkbox'] {
    display: none;
  }
  button {
    box-sizing: border-box;
    height: $input-height;
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 3px $primary-colour-dark;
    }
  }
  input[type='textfield'], input[type='password'] {
    box-sizing: border-box;
    height: $input-height;
  }
}

.grouped-inputs {
  display: inline-block;
  margin: 3px;
  > :first-child > * {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  > :last-child > * {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  > :not(:first-child):not(:last-child) > * {
    border-radius: 0;
    border-right: none;
  }
}

.tooltip {
  display: none;
}

:hover > .tooltip {
  display: block;
  z-index: $z-tooltip;
  position: absolute;
  background-color: black;
  transform: translate(-50%,8px);
  top: 100%;
  left: 50%;
  color: white;
  padding: 3px 10px 5px 10px;
  border-radius: 3px;
  white-space: nowrap;

  .tooltip-arrow {
    position: absolute;
    top: -10px;
    left: 50%;
    margin: 0;
    margin-left: -5px;
    border-color: transparent transparent black transparent;
    border-width: 5px;
    border-style: solid;
  }
}
