@import 'variables';

.documents-page {
  text-align: center;
  padding-bottom: 100px;
  margin: 1em;

  .table-container {
    display: inline-block;
    max-width: 1000px;
  }

  .doc-table {
    margin: 1em 0;
    &__lastmod {
      &-heading {
        width: 10em;
      }
      &-content {
        color: $colour-medium-gray;
      }
    }
    &__title {
      &-content {
        a {
          color: $colour-black;
        }
      }
    }
  }

  .table-actions {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    > * {
      flex-grow: 0;
    }
    > :first-child {
      flex-grow: 1; // First child is search. We want it to fill the remaining space.
      text-align: left;
    }
  }

  .new-doc {
    display: inline-block;
    position: relative;
    &__popup {
      position: absolute;
      right: 0%;
      top: calc(100% + 0.5em);
      padding: 1em;
      background-color: white;
      border-radius: 1em;
      box-shadow: 0 3px 5px rgba(black,0.1);
      z-index: 1;
    }
  }

  .filter {
    display: inline-block;
    position: relative;
    &__popup {
      position: absolute;
      right: 0%;
      top: calc(100% + 0.5em);
      padding: 1em;
      background-color: white;
      border-radius: 1em;
      box-shadow: 0 3px 5px rgba(black,0.1);
      z-index: 1;
    }
  }

  .tag-select {
    position: relative;
    &__popup {
      position: absolute;
      left: 0%;
      top: calc(100% + 0.5em);
      padding: 1em;
      background-color: white;
      border-radius: 1em;
      box-shadow: 0 3px 5px rgba(black,0.1);
      z-index: 1;
    }
  }
}

@media only screen and (max-width: 1440px) {
  main.documents-page {
    .document-list-container {
      grid-template-columns: 5px 230px 1fr 5px;
    }
  }
}

@media only screen and (min-width: 1440px) {
  main.documents-page {
    .document-list-container {
      grid-template-columns: 1fr 300px calc(1440px - 310px) 1fr;
    }
  }
}
