.account-page {
  .link-account-form {
    margin: 2em;
    ul {
      padding: 0;
    }
    li {
      vertical-align: middle;
      list-style-type: none;
      span {
        margin-right: 10px;
      }
    }
  }
  .password-change-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    margin: 2em;
  }
  label {
    //margin: 10px;
    //.input {
    //  display: block;
    //}
  }
}
