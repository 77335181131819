.login-page {
  text-align: center;
  .oauth-links-container {
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .login-form-container {
    display: inline-block;
    label {
      display: block;
      margin: 10px;
      text-align: left;
      .input {
        display: block;
      }
    }
  }
}
