@import 'variables';

main.notes-page {
  text-align: center;
  margin-bottom: 100px;

  .notes-list-container {
    display: grid;
    column-gap: 10px;
    > * {
      justify-self: center;
    }
    .note-filter-container {
      grid-column: 2 / 2;
      width: 100%;
      box-sizing: border-box;
      text-align: left;
      padding: 10px;
      label {
        display: grid;
        grid-template-columns: min-content auto;
        > * {
          justify-self: start;
        }
      }
      .tag-selector {
        width: 100%;
        .dropdown {
          position: relative;
        }
      }
    }
  }
  .table-container{
    width: 100%;
    table {
      width: 100%;
      tr td .title {
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  main.notes-page {
    .notes-list-container {
      grid-template-columns: 5px 230px 1fr 5px;
    }
  }
}

@media only screen and (min-width: 1440px) {
  main.notes-page {
    .notes-list-container {
      grid-template-columns: 1fr 300px calc(1440px - 310px) 1fr;
    }
  }
}
